import React from 'react';
import Helmet from 'react-helmet'
import { Link } from 'gatsby'
import AppBar from '@material-ui/core/AppBar';
import CssBaseline from '@material-ui/core/CssBaseline';
import Container from '@material-ui/core/Container';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Dialog from '@material-ui/core/Dialog';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Slide from '@material-ui/core/Slide';
import Hidden from '@material-ui/core/Hidden';

import { makeStyles, useTheme } from '@material-ui/core/styles';
import { useMediaQuery } from '@material-ui/core';

import ThreeDRotation from '@material-ui/icons/ThreeDRotation'
import YouTubeIcon from '@material-ui/icons/YouTube';
import WebIcon from '@material-ui/icons/Web';

const useStyles = makeStyles(theme => ({
  margin: {
    margin: theme.spacing(1),
  },
}));

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});


function IndexPage(props) {
  const { data } = props;
  const classes = useStyles();

  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));

  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };


  const totalCountCollections = data.allBrandsJson.nodes.reduce(
    (accumulator, currentValue) => accumulator + currentValue.collectionsCount, 0);

  return (
    <React.Fragment>
      <CssBaseline />
      <Helmet>
        <meta charSet="utf-8" />
        <meta name="yandex-verification" content="368eb81ba18b72e8" />
        <title>ПлиткаБот - 3D раскладка плитки онлайн, дизайн-проект интерьера ванной комнаты</title>
      </Helmet>
      <AppBar position="relative">
        <Toolbar>
          <Typography variant="h6" noWrap>ПлиткаБот</Typography>
        </Toolbar>
      </AppBar>
      <Container>
        <Typography align="justify" paragraph>Автоматически построит 3D дизайн-проект ванной, санузла по вашим размерам. Бесплатно сделает раскладку плитки онлайн, посчитает количество и стоимость материалов. Доступно {'' + totalCountCollections} коллекций плитки под дерево, мрамор, камень, бетон и другие текстуры, {'' + data.allDesignsJson.totalCount} классических и современных дизайнов.</Typography>
        <Box align="middle" mb={5}>
          <Button variant="contained" href="/pwa/" color="primary" className={classes.margin}><ThreeDRotation />&nbsp;&nbsp;Дизайн онлайн</Button>
          <Button variant="contained" onClick={handleClickOpen} color="secondary" className={classes.margin}><YouTubeIcon />&nbsp;&nbsp;Как это работает</Button>
        </Box>

        <Typography variant="h6">Фото интерьеров ванных комнат</Typography>
        <Typography align="justify" paragraph>Варианты раскладки керамической плитки и керамогранита популярных производителей</Typography>
        <Box m={0} p={0} display="flex" flexWrap="wrap" justifyContent="space-between">
          {data.allBrandsJson.nodes.map((brand, index) => (
            <Box m={1} style={{flex: "1 1 200px"}} key={'extra-' + index}>
              <Button
                fullWidth
                component={Link} to={ '/' + brand.name + '/' }
                variant="outlined"
                startIcon={
                  <Avatar aria-label="бренд" style={{ backgroundColor: brand.color }}>
                    {brand.name[0].toUpperCase()}
                  </Avatar>
                }
              >
                <Box width="100%">
                  <Typography>{brand.fullName}</Typography>
                  <Typography variant="caption">коллекций: {brand.collectionsCount}</Typography>
                </Box>
              </Button>
            </Box>
          ))}
        </Box>
        <Box mt={5} mb={2} p={0} display="flex" flexWrap="wrap" justifyContent="space-between">
          <Box>
            <Typography paragraph>Контакты<br/><a href="mailto:info@plitkabot.ru">info@plitkabot.ru</a></Typography>
          </Box>
          <Box>
            <Button variant="outlined" component={Link} to="/setup/"><WebIcon />&nbsp;&nbsp;Установить на сайт</Button>
          </Box>
        </Box>
      </Container>

      <Dialog fullScreen={fullScreen} open={open} onClose={handleClose} TransitionComponent={Transition} maxWidth={false}>
        <AppBar position="relative">
          <Toolbar>
            <IconButton edge="start" color="inherit" onClick={handleClose} aria-label="close">
              <CloseIcon />
            </IconButton>
            <Typography variant="h6">
              Как это работает
            </Typography>
          </Toolbar>
        </AppBar>
        <Grid container justify = "center" style={{ paddingTop: 16, paddingBottom:  fullScreen ? 0 : 16, paddingLeft:  fullScreen ? 0 : 16, paddingRight: fullScreen ? 0 : 16}}>
          <Hidden smUp implementation="js">
            <iframe title="ПлиткаБот на телефоне" style={{width: "40vmax", height: "80vmax"}} src="https://www.youtube.com/embed/oAEpzjkjBsk?autoplay=0" frameBorder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
          </Hidden>
          <Hidden xsDown implementation="js">
            <iframe title="ПлиткаБот на компьютере" width="960" height="540" src="https://www.youtube.com/embed/5wpcl1ofje0?autoplay=0" frameBorder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
          </Hidden>
        </Grid>
      </Dialog>
    </React.Fragment>
  );
}


// component={Link} to={ '/' + brand.name }
// component="a" href={ '/' + brand.name }

// all Screenshots Json(filter: {done: {eq: true}}) {
//   totalCount
// }

export default IndexPage;

export const query = graphql`
  query {
    allBrandsJson {
      nodes {
        name
        fullName
        color
        designsCount
        collectionsCount
      }
    }
    allDesignsJson {
      totalCount
    }
  }
`
